import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import { ChevronUp, ChevronDown } from 'react-feather';

import { prepareHandle } from '../utils/uri-utils';

import styles from '../styles/store-menu.module.scss';

const StoreMenu = ({ title }) => {
	const data = useStaticQuery(
		graphql`
            query {
                productTypes: allShopifyProduct {
                    group(field: productType) {
                        fieldValue
                    }
                }

                vendors: allShopifyProduct {
                    group(field: vendor) {
                        fieldValue
                    }
                }
            }
        `
	);

	const [open, setOpen] = useState(false);

	const toggleMobile = () => {
		setOpen((val) => (!val));
	};

	const ExpandItem = ({ label, uri, items, defaultExpand }) => {
		const [expand, setExpand] = useState(defaultExpand);

		const toggleExpand = () => {
			setExpand((val) => (!val));
		};

		return (
			<>
				<ListItem
					button
					onClick={toggleExpand}
					className={styles.item}
				>
					<ListItemText primary={label} />
				</ListItem>
				<Collapse
					in={expand}
					timeout='auto'
				>
					<List
						component='div'
						disablePadding
					>
						{items.map((x, i) => (
							<ListItem
								key={i}
								button
								component={Link}
								to={`/store/${uri}/${prepareHandle(x.fieldValue)}/`}
								className={styles.nested}
								activeClassName={styles.active}
							>
								<ListItemText
									primary={x.fieldValue}
									classes={{ primary: styles.primary }}
								/>
							</ListItem>
						))}
					</List>
				</Collapse>
			</>
		);
	}

	const Menu = ({ className, defaultExpand }) => (
		<List
			component='nav'
			className={clsx(styles.menu, className)}
		>
			<ListItem
				button
				component={Link}
				to='/store/'
				className={styles.item}
				activeClassName={styles.active}
			>
				<ListItemText
					primary='Featured'
					classes={{ primary: styles.primary }}
				/>
			</ListItem>
			<ListItem
				button
				component={Link}
				to='/store/all/'
				className={styles.item}
				activeClassName={styles.active}
			>
				<ListItemText
					primary='All Items'
					classes={{ primary: styles.primary }}
				/>
			</ListItem>
			<ExpandItem
				label='Categories'
				uri='category'
				items={data.productTypes.group.map((x) => ({ fieldValue: `${x.fieldValue}s` }))}
				defaultExpand={true}
			/>
			<ExpandItem
				label='Brands'
				uri='brand'
				items={data.vendors.group}
				defaultExpand={defaultExpand}
			/>
		</List>
	);

	return (
		<>
			<Button
				type='button'
				fullWidth={true}
				onClick={toggleMobile}
				classes={{
					root: styles.browse,
					label: styles.label
				}}
			>
				<span>{title}</span>
				{open ?
					<ChevronUp className={styles.chevron} /> :
					<ChevronDown className={styles.chevron} />
				}
			</Button>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<Menu
					className={styles.mobileMenu}
					defaultExpand={false}
				/>
			</Collapse>
			<Menu
				className={styles.desktopMenu}
				defaultExpand={true}
			/>
		</>
	);
}

StoreMenu.props = {
	location: PropTypes.object.isRequired
};

export default StoreMenu;
