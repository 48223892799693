import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Button from '@material-ui/core/Button';

import StoreMenu from './store-menu';
import ProductGrid from './product-grid';

import styles from '../styles/store.module.scss';

const StoreContent = ({ title, items, includeViewAll, message }) => {
	return (
		<section className={styles.store}>
			<div className={styles.content}>
				<div className={styles.alert}>
					<Link to='/monthly-jewelry-subscription/'>Become a member to get 25% off our store!</Link>
				</div>
				<div className={styles.split}>
					<aside className={styles.menu}>
						<StoreMenu title={title} />
					</aside>
					<div className={styles.products}>
						<h4>{title}</h4>
						<ProductGrid
							products={items}
						/>
						{includeViewAll &&
							<Button
								className={styles.viewall}
								variant='outlined'
								color='primary'
								fullWidth={true}
								component={Link}
								to='/store/all/'
							>
								BROWSE MORE ITEMS
                            </Button>
						}
						{message &&
							<p className={styles.message}>{message}</p>
						}
						<hr />
					</div>
				</div>
			</div>
		</section>
	);
};

StoreContent.defaultProps = {
	includeViewAll: false,
	message: null
};

StoreContent.props = {
	title: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	includeViewAll: PropTypes.bool,
	message: PropTypes.string
};

export default StoreContent;
