import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ArrowRight } from 'react-feather';

import buttonStyles from '../styles/buttons.module.scss';

const AddToCart = ({ className, onClick }) => (
    <button
        type='button'
        className={clsx(buttonStyles.link, buttonStyles.addtocart, className)}
        onClick={onClick}
    >
        <span>Add to cart</span>
        <ArrowRight size={14} />
    </button>
);

AddToCart.props = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired
};

export default AddToCart;