import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import StoreContext from '../context/store-context';
import ProductPrice from './product-price';
import AddToCart from './add-to-cart';

import styles from '../styles/product-grid.module.scss';

const ItemGrid = ({ products, filtered }) => {
    const context = useContext(StoreContext);

    const ListItems = () => {
        if (!products.length) {
            if (filtered) {
                return (
                    <li className={styles.empty}>Sorry, no results match your filter.</li>
                );
            }

            return (
                <li className={styles.empty}>Sorry, no products are available at the moment. Please check back later!</li>
            );
        }

        return products.map((x) => (
            <li key={x.node.id}>
                <Link
                    to={`/product/${x.node.handle}/`}
                    className={styles.card}
                >
                    <div className={styles.top}>
                        <Img
                            className={styles.img}
                            fluid={x.node.images[0].localFile.childImageSharp.fluid}
                            alt={x.node.handle}
                        />
                    </div>
                    <div className={styles.product}>
                        {x.node.vendor &&
                            <div className={styles.vendor}>{x.node.vendor}</div>
                        }
                        <h2>{x.node.title}</h2>
                        {/* <small className={styles.type}>{x.node.productType}</small> */}
                        <div className={styles.price}>
                            <ProductPrice
                                value={x.node.variants[0].price}
                                showCompare={true}
                            />
                        </div>
                        <AddToCart
                            className={styles.addtocart}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                context.addVariantToCart(
                                    x.node.shopifyId,
                                    x.node.variants[0].shopifyId,
                                    1
                                );

                                return false;
                            }}
                        />
                    </div>
                </Link>
            </li>
        ));
    };

    return (
        <ul className={styles.list}>
            <ListItems />
        </ul>
    );
};

ItemGrid.defaultProps = {
    filtered: false
};

ItemGrid.props = {
    products: PropTypes.array.isRequired,
    filtered: PropTypes.bool
};

export default ItemGrid;
